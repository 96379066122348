import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"

const PageContent = () => {
  const articles = [
    {
      title: "Carbon Steel and X Grade Linepipe",
      content:
        "Carbon steel pipe and linepipe make up the staple of our work, both welded (ERW, HFW) and seamless constructions. Sizes ranging from DN32 to DN900 in Schedule 20 through to Schedule 160 and XXS are all possible with induction bending. Custom wall sizes, outside diameters and many other variations can all be accomodated.",
      link: "/materials/carbon-steel-and-x-grade-linepipe",
    },
    {
      title: "Stainless Steel and Duplex",
      content:
        "Stainless steels and duplex stainless steels used in the chemical, petrochemical, water, water treatment and food industries.",
      link: "/materials/stainless-steel-and-duplex",
      extra: [
        "Sizes DN40 through DN500",
        "Schedules 10S through 80S",
        "TP304L, TP316L, TP321",
        "UNS S31803, UNS S32205 (Sandvik SAF 2205)",
        "Super duplex UNS S32750 (Sandvik SAF 2507)",
      ],
    },
    {
      title: "Structural Sections",
      content:
        "Square hollow section, rectangular hollow section, circular hollow section, parallel flange channel, universal columns and universal beams can be bend with minimal to no residual stress to exacting tolerances and tight radii.",
      link: "/materials/structural-sections",
      extra: ["SHS, RHS, CHS", "PFC", "UC, UB"],
    },
    {
      title: "Carbon Steel and X Grade Linepipe",
      content:
        "Nickel alloys such as Inconel 600, Incoloy, Hastelloy  and Monel along with exotic materials such as titanium are no problem for Inductabend.",
      link: "/materials/carbon-steel-and-x-grade-linepipe",
    },
    {
      title: "Chromoly Pipe",
      content:
        "Predominantly used in the offshore oil and gas and power generation industries, chromoly pipe can be bent with wall thicknesses up to 75mm. Our post heat treatment facilities allow for tight control of material properties without compromising dimensional accuracy.",
      link: "/materials/chromoly-pipe",
    },
    {
      title: "Nickel Alloys and Exotics",
      content:
        "Nickel alloys such as Inconel 600, Incoloy, Hastelloy  and Monel along with exotic materials such as titanium are no problem for Inductabend.",
      link: "/materials/nickel-alloys-and-exotics",
    },
  ]

  return (
    <div className="container">
      <div className="page-content">
        <div className="article-group">
          {articles.map(article => (
            <div className="article">
              <h3>{article.title}</h3>
              <p>{article.content}</p>
              {article.extra && (
                <ul>
                  {article.extra.map(value => (
                    <li>{value}</li>
                  ))}
                </ul>
              )}
              <div className="article-action">
                <Link to={article.link}>
                  <FontAwesomeIcon icon={faPlay} />
                  READ MORE
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PageContent
